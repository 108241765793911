<script setup lang="ts">
import integrations from '@respell/integrations';
import { categories } from '@respell/steps';
import StepCard from './StepCard.vue';

const primaryKey = useRouteParams('primary');
const secondaryKey = useRouteParams('secondary');

const canvasStore = useCanvasStore();
const { typeFilter, stepOptions, stepsByService } = storeToRefs(canvasStore);
const { addingStep } = useSelectedStep();
const { routeName } = useRouteName();

if (routeName.value === 'integrations') {
  typeFilter.value = categories.find(
    (category) => category.key === 'integrations',
  );
}

const expandedServices = ref(
  new Set(primaryKey.value && !secondaryKey.value ? [primaryKey.value] : []),
);

const toggleExpand = (service) => {
  if (expandedServices.value.has(service)) {
    expandedServices.value.delete(service);
  } else {
    expandedServices.value.add(service);
  }
};
</script>
<template>
  <div
    class="flex flex-col gap-3 h-full w-full justify-start items-start px-3.5 divide-y"
    :class="addingStep ? 'pt-s' : 'pt-m'"
  >
    <div class="flex w-full justify-between">
      <span class="flex flex-row gap-4">
        <UIcon :name="typeFilter.icon" class="text-xl text-gray-500" />
        <p class="title">{{ typeFilter?.name }}</p>
      </span>
      <UButton
        v-if="!addingStep"
        :padded="false"
        color="gray"
        variant="link"
        icon="i-ph-x"
        @click="canvasStore.resetSidebar"
      />
    </div>

    <div
      v-if="typeFilter.key === 'integrations'"
      class="overflow-y-scroll py-3.5"
    >
      <div
        v-for="(steps, key) in stepsByService"
        :key="key"
        class="flex flex-col w-full items-start mb-2"
      >
        <UAccordion
          :items="[
            {
              label: integrations[key]?.name,
            },
          ]"
          variant="ghost"
          :ui="{
            item: {
              base: '!p-0',
            },
          }"
        >
          <template #default="{ item, open }">
            <UButton
              color="gray"
              variant="ghost"
              block
              :disabled="steps?.length < 3"
              class="border-b border-gray-200 dark:border-gray-700"
              :ui="{ rounded: 'rounded-none', padding: { sm: 'p-3' } }"
              @click="toggleExpand(key)"
            >
              <template #leading>
                <UIcon
                  :name="integrations[key]?.icon"
                  class="rounded-md border-gray-50 outline outline-gray-200 border bg-white text-2xl"
                />
              </template>

              <span class="title dimmed ml-1">{{ item.label }}</span>

              <template #trailing>
                <p
                  class="caption ms-auto"
                  :class="{
                    invisible: steps?.length < 3 || expandedServices.has(key),
                  }"
                >
                  Show all {{ steps?.length }}
                </p>
                <UIcon
                  name="i-ph-caret-right"
                  class="w-5 h-5 transform transition-transform duration-200"
                  :class="{ 'rotate-90': open, invisible: steps?.length < 3 }"
                />
              </template>
            </UButton>
          </template>
        </UAccordion>
        <div
          class="grid overflow-hidden gap-3.5 w-full py-3.5"
          :class="[
            expandedServices.has(key)
              ? 'max-h-screen'
              : routeName === 'integrations'
                ? 'max-h-80'
                : 'max-h-40',
            routeName === 'integrations'
              ? 'grid-cols-2'
              : '2xl:grid-cols-2 grid-cols-1',
          ]"
          style="transition: max-height 0.25s ease-in-out"
        >
          <StepCard
            v-for="step in steps"
            :key="step.key"
            :step-type="step.key"
            draggable
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="grid 2xl:grid-cols-2 gap-3.5 grid-cols-1 w-full overflow-y-scroll py-3.5"
    >
      <StepCard
        v-for="(step, key) in stepOptions"
        :key="key"
        :step-type="key"
        draggable
      />
    </div>
  </div>
</template>

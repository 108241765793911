<script lang="ts" setup>
import { subject } from '@casl/ability';
import definitions from '@respell/steps';
import { useVueFlow } from '@vue-flow/core';

const props = defineProps({
  stepType: {
    type: String,
    required: true,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
});

const { can } = useAppAbility();

const workspaceStore = useWorkspaceStore();
const { subscription } = storeToRefs(workspaceStore);

const step = definitions[props.stepType];

const canvasStore = useCanvasStore();
const { addStep, hydrateStep } = canvasStore;
const { onDragStart } = useDragAndDrop();
const { addingStep } = useSelectedStep();
const { routeName } = useRouteName();
const { screenToFlowCoordinate } = useVueFlow({ id: 'editor' });

const isGated = computed(
  () =>
    ['send_call'].includes(props.stepType) &&
    !can('dataSources', subject('Subscription', subscription.value)) &&
    routeName.value !== 'integrations',
);

const handleClick = (event: MouseEvent) => {
  if (addingStep.value) {
    hydrateStep(step.key);
  } else {
    const position = screenToFlowCoordinate({
      x: event.clientX,
      y: event.clientY,
    });
    addStep(step.key, position);
  }
};

const handleDrag = (event: DragEvent) => {
  if (props.draggable) {
    onDragStart(event, step.key);
  }
};
</script>
<template>
  <UTooltip
    :prevent="!isGated"
    text="A team plan is required to use this step."
  >
    <div
      :draggable="draggable"
      class="flex group flex-col border-container justify-start radius-m hover:shadow-select p-m w-80 h-36"
      :class="[
        isGated
          ? 'pointer-events-none cursor-not-allowed blur-[1px]'
          : draggable
            ? 'cursor-move'
            : 'cursor-pointer',
      ]"
      @click="handleClick"
      @dragstart="handleDrag"
    >
      <div class="flex flex-row justify-start w-full mb-s gap-2">
        <UIcon
          :name="step?.icon"
          class="rounded-full border-gray-50 outline outline-gray-200 border bg-white text-2xl"
        />
        <p class="subtitle truncate mr-auto">
          {{ step?.name }}
        </p>
        <UIcon
          v-if="draggable"
          name="i-ph-dots-six-vertical-bold"
          class="invisible group-hover:visible text-gray-400 text-2xl shrink-0"
        />
      </div>
      <p class="body dimmed font-thin w-full text-left line-clamp-3">
        {{ step?.description }}
      </p>
    </div>
  </UTooltip>
</template>
